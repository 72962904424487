<template>
  <div class="slamDunk-box">
    <iframe
      v-if="frameSrc && status === 'going'"
      :src="frameSrc"
      height="100%"
      width="100%"
      frameborder="0"
    ></iframe>
    <div
      v-else
      :class="{
        'game-not-played': true,
        'game-not-started': status === 'unstart',
        'game-end': status === 'finish',
      }"
    ></div>
    <!-- <div class="slamDunk-bg">
      <el-row>
        <el-col :span="4">
          <left />
        </el-col>
        <el-col :span="16">
          <mid />
        </el-col>
        <el-col :span="4">3</el-col>
      </el-row>
    </div> -->
  </div>
</template>
<script >
export default {
  name: "slamdunk",
  data() {
    return {
      uniq: "", // 活动id
      colle_uniq: "", // 合集id
      frameSrc: "", // 游戏页面地址
      status: "",
    };
  },
  methods: {
    getParentData(data) {
      const { activity } = data;

      const curTime = new Date().getTime();
      if (curTime < activity.begin_time * 1000) {
        this.status = "unstart";
        return;
      }
      if (curTime > activity.end_time * 1000 || activity?.is_termi == 2) {
        this.status = "finish";
        return;
      }
      this.status = "going";
      if (this.uniq !== activity.uniq) {
        this.colle_uniq = this.$route.params.id;
        const nowTime = new Date().getTime();
        this.uniq = activity.uniq;
       // this.frameSrc = `https://store-h5.chinamallsales.com/game/bigSoccer/index.html?colle_uniq=${this.colle_uniq}&uniq=${this.uniq}&nowTime=${nowTime}`
        this.frameSrc = `https://game-h5.chinamallsales.com/bigSoccer/build/master-web-mobile/index.html?colle_uniq=${this.colle_uniq}&uniq=${this.uniq}&nowTime=${nowTime}`;
      }
    },
  },
};
// import left from "./components/left/index.vue";
// import mid from "./components/mid/index.vue";
// import { useRoute } from "vue-router";
// const route = useRoute();
// // colle_uniq=263993f7cc18ca1e&uniq=e410a5427e81ee4b
// const colle_uniq = route.params.id;
// const frameSrc = `https://xm-store-h5.chinamallsales.com/game/bigSoccer/index.html?colle_uniq=${colle_uniq}`;
</script>
<style scoped>
.slamDunk-box {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: url(../../assets/slamDunk/bg.png) no-repeat; */
  /* width: 100%;
  background-size: 100% auto; */
}

.game-not-played {
  width: 100%;
  height: 100%;
}

.game-not-started {
  background: url(./imgs/notStarted.png) no-repeat;
  background-size: 100% auto;
  background-color: #44d3fb;
  background-position: center;
}
.game-end {
  background: url(./imgs/end.png) no-repeat;
  background-size: 100% auto;
  background-color: #44d3fb;
  background-position: center;
}
</style>